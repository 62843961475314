import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'coinnexus',
  description:
    'The most popular AMM on CNS by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by coinnexus), NFTs, and more, on a platform you can trust.',
  image: 'images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('CNSSwap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('CNSSwap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('CNSSwap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('CNSSwap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('CNSSwap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('CNSSwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('CNSSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('CNSSwap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('CNSSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('CNSSwap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('CNSSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('CNSSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('CNSSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('CNSSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('CNSSwap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('CNSSwap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('CNSSwap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('CNSSwap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('CNSSwap Info & Analytics')}`,
        description: 'View statistics for coinnexus exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('CNSSwap Info & Analytics')}`,
        description: 'View statistics for coinnexus exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('CNSSwap Info & Analytics')}`,
        description: 'View statistics for coinnexus exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('CNSSwap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('CNSSwap')}`,
      }
    case '/nfts/activity':
      return {
        title: `${t('Activity')} | ${t('CNSSwap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Profile')} | ${t('CNSSwap')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('CNSSwap')}`,
      }
    default:
      return null
  }
}
